<template>
<tr class="suggestion-container-row">
  <td colspan="3">
    <div class="suggestion-table-container">
      <table class="data_table suggestion_table">
        <tr v-for="(cur_suggestion,suggestion_index) in ingredient.suggestions" v-on:click="suggestion_changed(ingredient, cur_suggestion);" v-bind:key="suggestion_index" v-bind:class="{'is-selected': ingredient.title == cur_suggestion.title }">
            <td>
              <span class="simple-button">
                <p-radio class="p-default p-round" color="success" :checked="ingredient.title == cur_suggestion.title">
                    {{ capitalize(cur_suggestion.title) }}
                </p-radio>
              </span>
          </td>
            <td></td>
            <td class="right">
              <span v-html="suggestion_change_in_percent(ingredient.co2e, cur_suggestion.co2e)"></span>
            </td>
        </tr>
      </table>
    </div>
  </td>
</tr>
</template>

<script>
import Helper from "@/utils/Helper.js"
import PrettyRadio from 'pretty-checkbox-vue/radio';

export default {
  name: 'Suggestions',
  props: {
    "ingredient": Object
  },
  components: {  'p-radio': PrettyRadio },
  methods: {
    suggestion_changed(ingredient, suggestion) {
      this.$emit("changed", ingredient, suggestion);
    },
    capitalize: Helper.capitalize,
    suggestion_change_in_percent(original_co2e, suggestion_co2e) {
      var percent = Math.round(((suggestion_co2e/original_co2e)*100)-100)
      if(percent == 0 || (!original_co2e && !suggestion_co2e)) {
        return "&plusmn;0%"
      } else if (!isFinite(percent)) {
        return "-";
      } else if(percent > 0) {
        return "+" + percent + "%"
      }
      else {
        return percent + "%"
      }
    }
  }
}
</script>

