let category_options = [
   { "group": "Frukt/grönt", "label": "Frukt import", "value": 63, "co2e": 0.6 },
    { "group": "Frukt/grönt", "label": "Frukt Norden", "value": 62, "co2e": 0.2 },
    { "group": "Frukt/grönt", "label": "Grönt/frukt flyg", "value": 67, "co2e": 11 },
    { "group": "Frukt/grönt", "label": "Juice och sylt", "value": 68, "co2e": 3 },
    { "group": "Frukt/grönt", "label": "Rotfrukter, lök och kål", "value": 66, "co2e": 0.2 },
    { "group": "Frukt/grönt", "label": "Salladsgrönsaker Import", "value": 65, "co2e": 1.4 },
    { "group": "Frukt/grönt", "label": "Salladsgrönsaker Norden", "value": 64, "co2e": 1 },
    { "group": "Proteinkällor", "label": "Baljväxter", "value": 51, "co2e": 0.7 },
    { "group": "Proteinkällor", "label": "Chark", "value": 45, "co2e": 7 },
    { "group": "Proteinkällor", "label": "Fisk och skaldjur", "value": 46, "co2e": 3 },
    { "group": "Proteinkällor", "label": "Fläskkött", "value": 42, "co2e": 6 },
    { "group": "Proteinkällor", "label": "Fågelkött", "value": 43, "co2e": 3 },
    { "group": "Proteinkällor", "label": "Köttfärs", "value": 44, "co2e": 16 },
    { "group": "Proteinkällor", "label": "Köttsubstitut", "value": 49, "co2e": 3 },
    { "group": "Proteinkällor", "label": "Lammkött", "value": 40, "co2e": 21 },
    { "group": "Proteinkällor", "label": "Nötkött", "value": 39, "co2e": 26 },
    { "group": "Proteinkällor", "label": "Nötter", "value": 50, "co2e": 1.5 },
    { "group": "Proteinkällor", "label": "Quorn", "value": 48, "co2e": 4 },
    { "group": "Proteinkällor", "label": "Tofu", "value": 78, "co2e": 0.982 },
    { "group": "Proteinkällor", "label": "Viltkött", "value": 41, "co2e": 0.5 },
    { "group": "Proteinkällor", "label": "Ägg", "value": 47, "co2e": 2 },
    { "group": "Kolhydratkällor", "label": "Bröd", "value": 60, "co2e": 0.8 },
    { "group": "Kolhydratkällor", "label": "Mjöl, socker, gryn", "value": 61, "co2e": 0.6 },
    { "group": "Kolhydratkällor", "label": "Pasta", "value": 59, "co2e": 0.8 },
    { "group": "Kolhydratkällor", "label": "Potatis", "value": 58, "co2e": 0.1 },
    { "group": "Kolhydratkällor", "label": "Ris", "value": 57, "co2e": 2 },
    { "group": "Mejeriprodukter", "label": "Grädde", "value": 53, "co2e": 4 },
    { "group": "Mejeriprodukter", "label": "Mejeri övrigt", "value": 56, "co2e": 2 },
    { "group": "Mejeriprodukter", "label": "Mjölk, fil, yoghurt", "value": 52, "co2e": 1 },
    { "group": "Mejeriprodukter", "label": "Ost", "value": 54, "co2e": 8 },
    { "group": "Mejeriprodukter", "label": "Smör", "value": 55, "co2e": 8 },
    { "group": "Fetter, såser och kryddor", "label": "Margarin", "value": 69, "co2e": 1.5 },
    { "group": "Fetter, såser och kryddor", "label": "Olja", "value": 70, "co2e": 1.5 },
    { "group": "Fetter, såser och kryddor", "label": "Sås, kryddor", "value": 71, "co2e": 1 },
    { "group": "Utrymmesmat", "label": "Chips", "value": 75, "co2e": 2 },
    { "group": "Utrymmesmat", "label": "Glass", "value": 76, "co2e": 2 },
    { "group": "Utrymmesmat", "label": "Godis", "value": 74, "co2e": 2 },
    { "group": "Utrymmesmat", "label": "Kaffe, te", "value": 72, "co2e": 3 },
    { "group": "Utrymmesmat", "label": "Läsk", "value": 73, "co2e": 0.3 },
    { "group": "Färdigrätter", "label": "Färdigrätt med fisk", "value": 81, "co2e": 1 },
    { "group": "Färdigrätter", "label": "Färdigrätt med kött", "value": 80, "co2e": 2 },
    { "group": "Färdigrätter", "label": "Färdigrätt vegetarisk", "value": 82, "co2e": 0.5 },
    { "group": "Övrigt vegetariskt", "label": "Vegetabilisk mjölk", "value": 79, "co2e": 0.75 },
    { "group": "Special", "label": "Ej inräknad", "value": 83, "co2e": 0 },
    { "group": "Special", "label": "Marginell påverkan", "value": 77, "co2e": 0 },
    { "group": "Special", "label": "Kunde ej kategorisera", "value": 84, "co2e": 0 },
    ]

export default {
    category_options,
    category_co2e(category_id) {
        if(category_id) {
          return category_options.find(x => x.value == category_id).co2e;
        }
        else {
          return 0
        }
    },
    category_title(category_id) {
        if(category_id) {
            return category_options.find(x => x.value == category_id).label;
        }
        else {
            return "N/A"
        }
    }
}