export default {
    trackEvent(curVue, category, action, label, value) {
        curVue.$gtm.trackEvent({
            event: null, // Event type [default = 'interaction'] (Optional)
            category: category,
            action: action,
            label: label,
            value: value,
            noninteraction: false // Optional
        });
    }
}