<template>
<table id="ingredients" class="data_table" style="table-layout: auto; border-collapse: collapse;">
    <tr>
        <th>{{caption}}</th>
        <th colspan=2 class="right">Koldioxid</th>
    </tr>
    <template v-for="(cur,index) in ingredients">
      <IngredientRow @toggle-suggestion="toggle_suggestion_box(cur)" v-bind:key="index + 'a'" :ingredient="cur" />
      <SuggestionsRow v-bind:key="index + 'b'" v-if="cur.show_suggestion" @changed="(ingredient, suggestion) => replace_ingredient(ingredient, suggestion)" :ingredient="cur" />
    </template>
    <tr v-if="showTotal"><td><strong>Totalt</strong></td><td class="right" colspan=2><strong>{{total_co2e*1000 | round(0)}}</strong></td></tr>
    
</table>
</template>

<style lang="scss">
@import '../assets/css/ingredients.scss';
</style>

<script>
import Analytics from '@/utils/Analytics.js'
import Helper from '@/utils/Helper.js'
import SuggestionsRow from '@/components/SuggestionsRow.vue'
import IngredientRow from '@/components/IngredientRow.vue'
import round from 'vue-round-filter';

export default {
  name: 'Ingredients',
  props: {
    "caption": {
      type: String,
      default: "Ingredienser"
    },
    "ingredients": Array,
    "showTotal": Boolean
  },
  components: {IngredientRow, SuggestionsRow },
  filters: {
    round,
  },
  computed: {
    total_co2e: function() {
      return this.ingredients.reduce((acc, cur) => acc + cur.co2e, 0)
    }
  },
  methods: {
    capitalize: Helper.capitalize,
    track_click_event(label) {
      Analytics.trackEvent(this, "Ingredients", "click", label, false)
    },
    // suggestion_change_in_percent(original_co2e, suggestion_co2e) {
    //   var percent = Math.round(((suggestion_co2e/original_co2e)*100)-100)
    //   if(percent == 0 || (!original_co2e && !suggestion_co2e)) {
    //     return "&plusmn;0%"
    //   } else if (!isFinite(percent)) {
    //     return "-";
    //   } else if(percent > 0) {
    //     return "+" + percent + "%"
    //   }
    //   else {
    //     return percent + "%"
    //   }
    // },
    // capitalize(s) {
    //   if (typeof s !== 'string') return ''
    //   return s.charAt(0).toUpperCase() + s.slice(1)
    // },
    replace_ingredient(ingredient, suggestion) {
      ingredient.co2e = suggestion.co2e
      ingredient.title = suggestion.title
      ingredient.show_suggestion = false
      this.$emit("changed");
    },
    toggle_suggestion_box(ingredient) {
      if(ingredient.suggestions.length > 0) {
        ingredient.show_suggestion = !ingredient.show_suggestion
        if(ingredient.show_suggestion) {
          this.track_click_event("show suggestions")
        }
        else {
          this.track_click_event("hide suggestions")
        }
      }
    }
  }
}
</script>