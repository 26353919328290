<template>
  <div id="thermometer">
    <div id="bar-bg"></div>
    <div id="bar" :style="style"></div>
    <img src="../assets/thermometer.svg">
  </div>
</template>

<script>
export default {
  name: 'Thermometer',
  props: {
    percent: Number
  },
  computed: {
    style () {
        var percent_with_limit = this.percent > 200 ? 200 : this.percent
        var hue    = (117-(30*((Math.pow(percent_with_limit, 1.24))/200)));
        var height = ((percent_with_limit/200)*176);

        // height = height > 176 ? 176 : height;

        return 'height: ' + height + 'px; background-color: hsl(' + hue + ', 100%, 48%)';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#thermometer {
    height: 200px;
    width: 50px;
    position: relative;
}

img {
    height: 100%;
}

#bar {
    position: absolute;
    bottom: 11px;
    left: 10px;
    width: 30px;
    // height: 176px;
    height: 10px;
    // background-color: #FFA700;
    // background-color:hsl(39,100%,50%);
    z-index: -1;
    transition: height 1s, background-color 1s;
    // transition-delay: 2s;
    // animation: 1s ease-out 0s 1 bar-init-animation;
}

// @keyframes bar-init-animation {
//   0% {
//     height: 0;
//   }
//   100% {
//     height: auto;
//   }
// }

#bar-bg {
    position: absolute;
    bottom: 11px;
    left: 10px;
    width: 30px;
    height: 176px;
    z-index: -2;
    background-color: white;
}
</style>
