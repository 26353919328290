<template>
  <div class="post submit-data-component standard-side-margins" v-if="submit_data_view">

      <!-- Start page -->
      <div v-if="!fill_in_mode">
        <h1 class="start-heading">Saknade matvaror</h1>
        <p class="submit-intro-text">
          Alla matvaror kunde inte identifieras. Få en fullständig analys genom att kategorisera det som saknas.
        </p>
        <p class="submit-intro-text">
          Du hjälper också andra genom att kategorisera.
        </p>
        <div class="button-container">
          <button class="button button-categorize"  @click="track_click_event('start categorizing'); start()">Kategorisera {{num_missing_ingredients}} matvaror</button><br>
          <button class="button alt_button" @click="track_click_event('skip all'); complete()">Fortsätt utan att fylla i</button>
        </div>
      </div>
      <!-- Start page -->
      
      <div class="submitdata_inputs" v-if="fill_in_mode">

        <div class="submitdata_top_container">
          <div>
            <h1>{{cur_ingredient.original_amount}} {{cur_ingredient.original_title}}</h1>
            <h2 v-if="num_missing_ingredients > 1">{{cur_ingredient_index+1}} av {{num_missing_ingredients}}</h2>
          </div>
          <v-select v-if="cur_ingredient.category == 'N/A' && !cur_ingredient.item_specific_co2e" v-model="category" :options="category_options" :searchable=false class="category-select">
            <template slot="option" slot-scope="option">
                {{option.label}} <span class="category_group">{{option.group}}</span>
            </template>
          </v-select>
          
          <div v-bind:class="{ amount_input_container: true, one_line_amount_text: true  }" v-if="!cur_ingredient.could_parse_to_gram">
            <div class="amount_input_inner_container">
              <div class="amount_string_info">
                Vikt i gram:&nbsp;
              </div>
              <input type="text" v-model="amount_string" id="amount_string" class="input">
            </div>
          </div>

          <p v-if="show_weight_error">
            Du måste fylla i vikt.
          </p>

          <p v-if="show_category_error">
            Du måste välja en kategori.
          </p>
        </div>

        <button v-if="is_last_ingredient" class="button complete-button"  @click="track_click_event('done'); next_and_save()">
          Klar
        </button>
        <div v-if="!is_last_ingredient">
          <button class="button button-categorize"  @click="track_click_event('categorized item'); next_and_save()">
            Kategorisera
          </button>
          <br>
        </div>
        <button class="button alt_button" @click="track_click_event('skip one'); next()">Hoppa över denna</button>
        <button class="button alt_button" @click="track_click_event('skip remaining'); complete()">Hoppa över alla</button>

      </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/css/submit-data.scss";
</style>

<script>
import VSelect from 'vue-select'
import Api from '@/utils/Api.js'
import Categories from '@/utils/Categories.js'
import Analytics from '@/utils/Analytics.js'

export default {
  props: ["recipes"],
  // props: ["recipes", "num_portions"],
  components: { VSelect },
  data () {
    return {
      cur_recipe_index: 0,      
      cur_ingredient_index: 0,      
      fill_in_mode: false,
      submit_data_view: true,
      amount_string: "",
      category_default: {value: 0, label: "Välj kategori", co2e: 0},
      category: {value: 0, label: "Välj kategori", co2e: 0},
      category_options: Categories.category_options,
      user_data: [],
      // is_last_ingredient: false,
      show_weight_error: false,
      show_category_error: false
  }},
  methods: {
    track_click_event(label) {
      Analytics.trackEvent(this, "SubmitData", "click", label, false)
    },
    next_and_save() {
      console.log("save", this.cur_ingredient.category, this.cur_ingredient.could_parse_to_gram, this.category.value, this.amount_string)

      this.show_weight_error = false
      this.show_category_error = false
      
      if(this.cur_ingredient.category == "N/A" && this.category.value == 0) {
        this.show_category_error = true
        return
      }

      this.save_user_data(this.cur_recipe_index, this.cur_ingredient, this.category.value, this.amount_string)
      this.next()
    },
    next_receipe_index_with_ingredients() {
      console.log("next finder", this.cur_recipe_index, this.missing_ingredients.length, this.missing_ingredients)
      for(var i=this.cur_recipe_index+1; i < this.missing_ingredients.length; i++) {
        console.log("i", i) //this.missing_ingredients[i].length)
        if(this.missing_ingredients[i].length > 0) {
          return i
        }
      }

      return this.missing_ingredients.length-1
    },
    next() {
      console.log("next", this.cur_ingredient)

      // this.is_last_ingredient = this.cur_ingredient_index == this.num_missing_ingredients-1
      // this.is_last_ingredient = this.cur_recipe_index == this.recipes.length-1 && this.cur_ingredient_index == this.missing_ingredients[this.cur_recipe_index].length-1

      if(this.is_last_ingredient) {
        console.log("complete 1")
        this.complete()
        return
      }
      // keep index from not overflowing on last save
      // this.cur_ingredient_index = this.cur_ingredient_index < this.num_missing_ingredients-1 ? this.cur_ingredient_index+1 : this.cur_ingredient_index
      
      // change recipe when all ingredients for a recipe is handled
      if(this.cur_ingredient_index == this.missing_ingredients[this.cur_recipe_index].length-1) {
        this.cur_ingredient_index = 0
        this.cur_recipe_index = this.next_receipe_index_with_ingredients()
      }
      else {
        this.cur_ingredient_index++
      }

      if(this.cur_recipe_index == this.missing_ingredients.length-1 && this.missing_ingredients[this.cur_recipe_index].length == 0) {
           console.log("complete 2")
           this.complete()
           return
      }

      // keep index from not overflowing on last save
      // if(this.cur_recipe_index == this.missing_ingredients.length) {
      //   this.cur_recipe_index = 0
      // }

      console.log("indexes", this.cur_recipe_index, this.cur_ingredient_index)

      if(this.cur_recipe_index < this.missing_ingredients.length && this.cur_ingredient_index < this.missing_ingredients[this.cur_recipe_index].length) {
        this.category             = this.category_default
        this.amount_string        = this.cur_ingredient.gram * this.cur_num_portions
      }
    },
    start() {
      this.cur_recipe_index = -1
      this.cur_recipe_index = this.next_receipe_index_with_ingredients()

      console.log("start", this.cur_recipe_index, this.cur_ingredient_index)
      console.log("missing ingredients", this.missing_ingredients)

      this.fill_in_mode=true
      this.amount_string = this.cur_ingredient.gram * this.cur_num_portions
    },
    // complete_if_last() {
    //   console.log("check")
    //   if(this.is_last_ingredient) {
    //     console.log("yes")
    //     this.complete()
    //   }
    // },
    complete() {
      var ingredient_ids = []
      var categories = []
      var grams = []

      this.user_data.forEach(function(cur) {
        ingredient_ids.push(cur.ingredient_id)
        categories.push(cur.category_id)
        grams.push(cur.gram)
      })

      console.log("ingredient_ids", ingredient_ids)
      console.log("categories", categories)
      console.log("grams", grams)

      this.submit_data_view = false

      Api.getSuggestionsFromCategories(ingredient_ids, categories, grams, function(suggestions) {
        console.log(suggestions)
        this.user_data.forEach(function(cur) {
          cur.suggestions = suggestions[cur.ingredient_id]
        })
        console.log("userdata", this.user_data)
        this.$emit("complete", this.user_data);
      }.bind(this))
      
    },
    co2e_for_ingredient(ingredient, category_id, gram) {
      let co2e = 0
      let category_co2e_per_kg = Categories.category_co2e(category_id)
      let item_specific_co2e_per_kg = ingredient.item_specific_co2e

      if(item_specific_co2e_per_kg && gram) {
        co2e = (gram/1000) * item_specific_co2e_per_kg
      } else if(gram) {
        co2e = (gram/1000) * category_co2e_per_kg
      }

      return co2e
    },
    save_user_data(recipe_index, ingredient, category_id, total_gram) {
      console.log(ingredient, category_id, total_gram, this.category)
      let gram = total_gram/this.cur_num_portions
      let co2e = this.co2e_for_ingredient(ingredient, category_id, gram)

      if(!category_id) {
        category_id = ingredient.category_id
      }

      if(!total_gram) {
        total_gram = ingredient.gram
      }

      console.log("total", total_gram, "could parse", ingredient.could_parse_to_gram, "igram", ingredient.gram);

      this.user_data.push({ recipe_index: recipe_index,
                            ingredient_id: ingredient.ingredient_id, 
                            category_id: category_id, 
                            category_title: Categories.category_title(category_id),
                            gram: gram, 
                            co2e: co2e })

      Api.saveUserData(ingredient.recipe_id, ingredient.ingredient_id, ingredient.food_item_id, category_id, total_gram, 
      (err, post) => {
        // console.log("done", err, post)
      })
    }
  },
  computed: {
    is_last_ingredient: function() {
      return this.cur_recipe_index == this.recipes.length || 
             (this.cur_recipe_index == this.recipes.length-1 && 
             this.cur_ingredient_index == this.missing_ingredients[this.cur_recipe_index].length-1)
    },
    cur_ingredient: function() {
      return typeof this.missing_ingredients[this.cur_recipe_index] !== "undefined" &&
             typeof this.missing_ingredients[this.cur_recipe_index][this.cur_ingredient_index] !== "undefined" ?
             this.missing_ingredients[this.cur_recipe_index][this.cur_ingredient_index] : null
    },
    cur_num_portions: function() {
      return this.recipes[this.cur_recipe_index].num_portions
    },
    num_missing_ingredients: function() {
      // console.log("out", this.missing_ingredients.reduce((acc, cur) => acc + cur.length, 0))
      return this.missing_ingredients.reduce((acc, cur) => acc + cur.length, 0)
    },
    missing_ingredients: function () {
      let missing_recipes = []

      this.recipes.forEach(function(recipe) {
        let missing_ingredients = []

        recipe.ingredients.forEach(function(cur) {
          if((cur.category == "N/A" && !cur.item_specific_co2e) || cur.gram == 0) {
             missing_ingredients.push(cur)
          }
        })

        missing_recipes.push(missing_ingredients)
      })

      return missing_recipes
    }
  }
}
</script>