<template>
<tr @click="$emit('toggle-suggestion')" :class="{'has-suggestion': ingredient.suggestions.length > 0}">
    <td style="width: 100%">
    <!-- Analyzed and over 3 gram, so we round to closest integer -->
    <span v-if="ingredient.gram >= 3 && ingredient.category != 'N/A'">
      {{ capitalize(ingredient.title) }}, {{ ingredient.gram | round(0)  }}&nbsp;g
    </span>
    <!-- Analyzed and under 3 gram, so we round to two decimals -->
    <span v-if="ingredient.gram != 0 && ingredient.gram < 3 && ingredient.category != 'N/A'">
      {{ capitalize(ingredient.title) }}, {{ ingredient.gram | round(2)  }}&nbsp;g
    </span>
    <!-- Could not analyze, no weight detected -->
    <span v-if="ingredient.gram == 0">
      <del>{{ capitalize(ingredient.title) }}</del>, okänd vikt
    </span>
    <!-- Could not analyze, no category detected -->
    <span v-if="ingredient.gram > 0 && ingredient.category == 'N/A'">
      <del>{{ capitalize(ingredient.title) }}</del>
    </span>
    <br>
    <span class="smaller-text" v-if="ingredient.item_specific_co2e">{{ capitalize(ingredient.title) }}</span>
    <span class="smaller-text" v-if="ingredient.category != 'N/A' && !ingredient.item_specific_co2e">{{ ingredient.category }}</span>
    <span class="smaller-text" v-if="ingredient.category == 'N/A' && !ingredient.item_specific_co2e">Kunde inte kategoriseras</span>
    </td>
    <td class="right lamp-icon-table-cell">
    <span class="fa suggestion-lamp-icon" v-if="ingredient.suggestions.length > 0 && ingredient.gram != 0">&#xf0eb;</span>
    </td>
    <td v-if="(ingredient.category != 'N/A' || ingredient.item_specific_co2e) && ingredient.gram > 0" class="right">
    {{ ingredient.co2e*1000 | round(0)  }}
    </td>
    <td v-if="(ingredient.category == 'N/A' && !ingredient.item_specific_co2e) || ingredient.gram == 0" class="right">?</td>
</tr>
</template>

<script>
import Helper from "@/utils/Helper.js"
import round from 'vue-round-filter';

export default {
  name: 'IngredientRow',
  props: {
    "ingredient": Object
  },
  filters: {
    round,
  },
  methods: {
      capitalize: Helper.capitalize
  }
}
</script>