<template>
  <div class="post desktop-standard-max-width">
    <div class="loading loading-margins" v-if="loading">
      <img src="../assets/fork-spinner.svg" class="spinner" style="display: none">

      <div class="loading-text-animation-container">
        <h2 class="loading-step-1">Räknar om volymmått
          och styckemått till gram</h2>

        <h2 class="loading-step-2">
          Kategoriserar matvaror
        </h2>

        <h2 class="loading-step-3">
          Summerar koldioxid<br>
          per portion
        </h2>
      </div>

    </div>

    <div v-if="error" class="error standard-side-margins">
      <p class="fa error-icon">&#xf071;</p>
      <p><strong>Det gick tyvärr inte att analysera ditt recept.</strong></p>
      <!-- Prova att mata in receptet manuellt under "Manuell" i menyn. -->
      <p>Felmeddelande: {{ error }}</p>
    </div>

    <SubmitData v-if="!analyzed_all && !error && !loading" :recipes="recipes" @complete="(user_data) => submit_data_completed(user_data)" />

    <div v-if="recipes && !loading && !error && analyzed_all" class="content">
      
      <h2 class="standard-side-margins"><a v-bind:href="url" target="_blank">{{ title }}</a></h2>
      <hr class="standard-side-margins">

      <div class="content-container">
        <div class="content-block-1 desktop-content-third summary-container standard-side-margins">
          <div class="summary-container-inside">
            <Thermometer v-bind:percent="percent" />
            <div class="summary-text" v-if="percent <= 100">

              <h2 v-if="percent < 50">Väldigt hållbart</h2>
              <h2 v-else-if="percent <= 100">Hållbar</h2>

              <p>{{ 100 - percent  }}% under gränsen, {{ total_co2e*1000 | round }} g koldioxid.<!-- Per portion från {{ num_portions }}.--></p>
              <p v-if="has_suggestions"><strong>Tryck på lampan</strong> för alternativa ingredienser.</p>
              <!-- <p>Debug Data: Of daily percent: {{ percent }}, co2e: {{ total_co2e }}</p> -->
              
            </div>
            <div class="summary-text" v-if="percent > 100">

              <h2 v-if="percent < 120">Nästan hållbar</h2>
              <h2 v-else-if="percent < 140">Inte hållbar</h2>
              <h2 v-else>Inte alls hållbart</h2>

              <p>{{ percent-100  }}% över gränsen, {{ total_co2e*1000 | round }} g koldioxid.</p>
              <p v-if="has_suggestions"><strong>Tryck på lampan</strong> för alternativa ingredienser.</p>
              <!-- <p>Debug Data: Of daily percent: {{ percent }}, co2e: {{ total_co2e }}</p> -->

            </div>
          </div>
          
        </div>

        <div class="content-block-1 desktop-content-two-thirds standard-side-margins ">
          <v-select v-model="meal_ref" :options="meal_ref_options" :searchable=false class="meal-ref-select" @input="ignore_first_meal_ref_input_change() && track_click_event('changed meal ref')"></v-select>
          <span v-if="recipes.length > 1">
            <Ingredients v-for="(cur,index) in recipes" v-bind:key="index+'i'" @changed="update_total_co2e()" :caption="'Recept ' + (index+1)" :ingredients="cur.ingredients" :showTotal="true" />
          </span>
          <Ingredients v-if="recipes.length == 1" @changed="update_total_co2e()" :ingredients="recipes[0].ingredients" />
        </div>
      </div>
      <div class="footer"><router-link :to="{ path: '/' }">Start</router-link> &nbsp; <router-link :to="{ path: 'about' }">Om appen</router-link></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../assets/css/recipe.scss';
</style>

<script>
import Api from '@/utils/Api.js'
import Categories from '@/utils/Categories.js'
import Analytics from '@/utils/Analytics.js'
import round from 'vue-round-filter';
import Thermometer from '@/components/Thermometer.vue';
import Ingredients from '@/components/Ingredients.vue';
import SubmitData from '@/components/SubmitData.vue';
import VSelect from 'vue-select'



export default {
  data () {
    return {
      loading: false,
      // post: null,
      title: null,
      recipes: null,
      error: null,
      total_co2e: null,
      // num_not_analyzed: 0,
      analyzed_all: true,
      has_suggestions: false,
      // percent: 0,
      daily_limit: null,
      meal_ref_options: [
                         {label: 'Middag 35% av intag', value: 0.35}, 
                         {label: 'Lunch 25% av intag', value: 0.25}, 
                         {label: 'Frukost 20% av intag', value: 0.2}, 
                         {label: 'Mellanmål 20% av intag', value: 0.2}, 
                         {label: 'Hela dagen 100% av intag', value: 1.0},
                         {label: 'Hela veckan 100% av intag', value: 7.0},
                         {label: 'Veckans middagar', value: 0.35*7.0},
                         {label: 'Veckans luncher', value: 0.25*7.0},
                         {label: 'Veckans frukostar', value: 0.2*7.0},
                         {label: 'Veckans mellanmål', value: 0.2*7.0},
                         ],
      meal_ref: {label: "Middag 35% av intag", value: 0.35},
      meal_ref_did_run: false
      // url: $route.query.url
    }
  },
  props: ["url", "text", "portions"],
  components: { Thermometer, Ingredients, SubmitData, VSelect },
  created () {

    if(this.url) {
      this.$gtm.trackView('/recipe', '/recipe-url')
    }
    else {
      this.$gtm.trackView('/recipe', '/recipe-text')
    }

    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  filters: {
    round,
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  computed: {
    percent: function () {
      return round((this.total_co2e/(this.daily_limit*this.meal_ref.value))*100)
    }
  },
  methods: {
    track_click_event(label) {
      Analytics.trackEvent(this, "Recipe", "click", label, false)
    },
    // there is a change event for the meal ref select that is executed on init
    // this is an ugly hack to ignore that first event
    ignore_first_meal_ref_input_change() {
      if(this.meal_ref_did_run) {
        return true;
      } else {
        this.meal_ref_did_run = true;
        return false;
      }
    },
    update_total_co2e() {
      var total_co2e = 0

      this.recipes.forEach(function(recipe) {
        recipe.ingredients.forEach(function(ingredient) {
          total_co2e += ingredient.co2e
        })
      })
      this.total_co2e = total_co2e
    },
    submit_data_completed(user_data) {
      this.analyzed_all = true

      this.recipes.forEach(function(recipe, cur_recipe_index) {
        recipe.ingredients.forEach(function(cur, index, ingredients) {
          let ingredient_user_data = user_data.find(x => x.recipe_index == cur_recipe_index && x.ingredient_id == cur.ingredient_id)

          // if the ingredient should be updated with user data
          if(ingredient_user_data) {
            cur.updated_with_user_data = true

            if(ingredient_user_data.category_id) {
              cur.category = ingredient_user_data.category_title
              cur.category_id = ingredient_user_data.category_id
            }

            if(!cur.could_parse_to_gram) { // ingredient_user_data.gram
              cur.gram = ingredient_user_data.gram
            }

            if(ingredient_user_data.co2e) {
              cur.co2e = ingredient_user_data.co2e
            }
            else if(cur.category) {
              // console.log("updated co2e", cur.co2e, Categories.category_co2e(cur.category_id), cur.gram)
              cur.co2e = Categories.category_co2e(cur.category_id) * (cur.gram/1000)
            }

            cur.suggestions = ingredient_user_data.suggestions
          }
          else {
            cur.updated_with_user_data = false
          }

          ingredients[index] = cur
        })

        // Resort so ingredients are sorted on the new values in co2e
        recipe.ingredients = recipe.ingredients.sort((a,b) => (b.co2e - a.co2e))
      })

        

        this.update_total_co2e()
    },
    handleApiError(err, start_ms) {
      this.loading = false
      this.error = err.toString()
    },
    waitForLoadingText(start_ms) {
      var api_call_in_ms = new Date().getTime() - start_ms
      var min_ms_for_loading_text = 6000
      var diff_ms = min_ms_for_loading_text - api_call_in_ms
      diff_ms = diff_ms < 0 ? 0 : diff_ms

      var that = this
      setTimeout(function() {
          that.loading = false
      }, diff_ms);
    },
    handleApiSuccess(recipes, start_ms) {
      console.log("done", recipes)
      // post = post[0]

      // Wait for atleast the time it takes to show the loading text
      this.waitForLoadingText(start_ms)


      this.title = recipes.length == 1 ? recipes[0].title : "Flera recept"

      this.recipes = recipes // = post.ingredients
      // this.total_co2e = post.total_co2e
      // this.num_portions = recipes[0].num_portions
      this.daily_limit = recipes[0].daily_limit

      this.analyzed_all = recipes.reduce((previous, cur) => cur.analyzed_all && previous, true) //post.analyzed_all
      // this.num_not_analyzed = post.num_ingredients_not_analyzed
      this.has_suggestions = recipes.reduce((previous, cur) => cur.has_suggestions || previous, false) //post.has_suggestions

      // this.percent = (this.total_co2e/this.daily_limit)*100
      // this.addSuggestionExcludeItem(this.ingredients)
      this.update_total_co2e()
      // console.log("post", post)
    },
    fetchData () {
      this.error = null
      this.loading = true

      var start_ms = new Date().getTime()

      if(this.url) {
        Api.getRecipeFromUrl(this.url, (err, post) => {
            if(!err) {
              console.log("post", post)
              this.handleApiSuccess([post], start_ms)
            } else {
              this.handleApiError(err, start_ms)
            }
        })
      }
      else if(this.text) {
        Api.getRecipesFromText(
          this.text,
          this.portions,
          (post) => this.handleApiSuccess(post, start_ms),
          (err) => this.handleApiError(err, start_ms)
          )
      }
      else {
        this.error = "Du har inte matat in något recept"
      }
    }
  }
}
</script>